.flex {
    display: flex;
}
.justify-content {
    justify-content: space-between;
}

.mt-10 {
    margin-top: 10%;
}
.ml-2 {
    margin-left: 2%;
}

.ml-5 {
    margin-left: 5%;
}
.ml-7 {
    margin-left: 11%;
}

.w-50{
    width: 300px;
}

.w-20{
    width: 200px;
}

.w-10{
    width: 237px;
}

.w-30{
    width: 100px;
}

.lightbox {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  
    background: rgba(0, 0, 0, 0.5);
  
    display: flex;
    align-items: center;
    align-items: center;
  
    visibility: hidden;
    opacity: 0;
  
    transition: opacity ease 0.4s;
  }
  
  .show {
    visibility: visible;
    opacity: 1;
  }
  
  .show_image {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
  }
  